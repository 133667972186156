import server from '@/api/http';

export async function getPublics(params){  
	let { data } = await server.get('marketing/publications_networks/settings/get', { params });
	return data;
}
export async function savePublic(params){  
	let { data } = await server.get('marketing/publications_networks/settings/save', { params });
	return data;
} 
export async function deletePublic(params){  
	let { data } = await server.get('marketing/publications_networks/settings/delete', { params });
	return data;
} 
export async function getPublication(params){  
	let { data } = await server.get('marketing/publications_networks/get', { params });
	return data;
}
export async function getDataAuth(params){  
	let { data } = await server.get('marketing/publications_networks/settings/auth/get', { params });
	return data;
}  
export async function upPublication(params){  
	let { data } = await server.post('marketing/publications_networks/up', params);
	return data;
} 
export async function deletePublication(params){  
	let { data } = await server.get('marketing/publications_networks/delete', { params });
	return data;
}
export async function loadFilesPublication(params){  
	let { data } = await server.post('marketing/publications_networks/download/files', params, { headers: {'Content-Type': 'multipart/form-data' } });
	return data;
}  
export async function deleteFilePublication(params){  
	let { data } = await server.get('marketing/publications_networks/file/delete', { params });
	return data;
}
export async function sendPublication(params){  
	let { data } = await server.get('marketing/publications_networks/send', { params });
	return data;
}
export async function getPublicationErrors(params){  
	let { data } = await server.get('marketing/publications_networks/errors/get', { params });
	return data;
}
 
 