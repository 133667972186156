import * as warehouseOrdersApi from '@/api/warehouse_orders.js';

export default {
	namespaced: true,
	state: {
		filter:null,
		table:[],
		page:1,
		get_row:20,
		quantity_page:null,  
		unity:{
			show:false,
			message:null,
			run:false,
			items:[]
		}
	},
	getters: {
		warehouse_orders:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setData(state, data){   
			state.table = data.table;   
			state.quantity_page = data.quantity_page;
		},
		setPage(state, page){ 
			state.page = page 
		},   
		setUnity(state, data){ 
			state.unity.show = data.show 
			state.unity.message = data.message 
			state.unity.items = data.items 
			state.unity.run = data.run 
		},
		showUnity(state, data){ 
			state.unity.show = data.status 
			state.unity.message = data.message 
			state.unity.run = data.run 
		},   
		addUnityItems(state, order_id){ 

			let add_status = true;
			let key = null;
			   
			if(state.unity.items.length > 0) 
				for(let key_unity in state.unity.items) 
					if(state.unity.items[key_unity] == order_id){ 
						add_status = false; 
						key = key_unity;
					}
			if(add_status)		 
				state.unity.items.push(order_id);	 
			else
				state.unity.items.splice(key, 1);
		},
		resetFilter(state){
			state.filter.order_id = null;
			state.filter.date = null;
			state.filter.shipment_from_id = null;
			state.filter.deal_id = null;
			state.filter.customers = null;
			state.filter.users_id = null;
			state.filter.status_id = null;
			state.filter.position_id = null;
			state.filter.category_product_id = null;
			state.filter.type_product_id = null;
			state.filter.class_product_id = null;
			state.filter.colour_product_id = null;
		} 
	},
	actions: { 
		async loadFilterWarehouseOrders ({ state, commit, dispatch, rootGetters }){

			let data = await warehouseOrdersApi.getFilter({user_id:rootGetters['account/account'].user_id, page:"warehouse_orders"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				await dispatch("loadDataWarehouseOrders", { filter:data.filter });
			}				 
		},    
		async setFilterWarehouseOrders ({ dispatch, rootGetters, commit }, filter){
			let data = await warehouseOrdersApi.setFilter({ user_id:rootGetters['account/account'].user_id, page:"warehouse_orders", filter });	 
			if(data.result) {
				commit('setPage', 1);  
				dispatch("loadDataWarehouseOrders", { filter:data.filter });
			}				 
		},
		async loadDataWarehouseOrders({ state, commit }, data){ 
			let response = await warehouseOrdersApi.getData({ filter:data.filter, page:state.page, get_row:state.get_row });	 
			if(response.result) {
				commit('setData', response);
				commit('setUnity', { show:false, message: null, items:[], run:false });	  
			}
		},
		changeUnity({ state, commit }, order_id){
		 
			let add_status = true;
			let deal_id = null;

			for(let items of state.table){ 
				if(Number(order_id) == Number(items.order_id)){ 
					if(items.deal.id == null){ 
						commit('showUnity', { status:true, message:"В заказе "+order_id+" не указан проект/сделка, объединение невозможно", run:false });	
						add_status = false;
					}
					else deal_id = Number(items.deal.id)
				}	
			}
			 
			if(add_status && state.unity.items.length >= 1){  
				for(let item_units of state.unity.items){   
					for(let items of state.table){  
						if(Number(item_units) == Number(items.order_id) && deal_id !== Number(items.deal.id)){ 
							commit('showUnity', { status:true, message:"Объединить заказы возможно только с одинаковым Проектом/сделкой", run:false});	
							add_status = false;
						}
					} 
				}
			}

			if(add_status){
				commit('addUnityItems', order_id)
			}	  
		},
		showUnity({ commit }, data){
			commit('showUnity', data);
		},   
		async runUnity({ state, dispatch }){
		 
			let response = await warehouseOrdersApi.runUnity({ orders:state.unity.items });	 
			if(response.result) {
				dispatch("loadDataWarehouseOrders", { filter:state.filter });
			} 
		},
		resetFilter({ state, commit, dispatch }){
			commit('resetFilter');	
			dispatch("loadDataWarehouseOrders", { filter:state.filter });	
		},
		async setDataWriteOffs({ dispatch }, data){

			let response = await warehouseOrdersApi.setDataWriteOffs({ order_id:data.order_id, date:data.e.target.value });	 
			if(response.result) {
				dispatch("snackBar/showSnackBar", "Данные обновлены!", { root: true }); 				
			}  
		}
	}, 
} 