import * as api from '@/api/publications_networks.js';

export default {
	namespaced: true,
	state: { 
		errors:[],  
	},
	getters: {
		publications_networks_errors:state=>{
			return state
		},  
	},
	mutations: { 
		setErrors(state, errors){ 
			state.errors = errors;	 
		},  
	},
	actions: { 
		async loadPublicationErrors({ commit }){ 			 
			await api.getPublicationErrors().then((response)=>{
				if(response.result) 
					commit('setErrors', response.errors);	  
			})	 
		}, 
	}
} 