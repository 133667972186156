import * as api from '@/api/publications_networks.js';

export default {
	namespaced: true,
	state: { 
		publications:[],   
		delete:{
			status:false,
			id:null
		}
	},
	getters: {
		publications_networks_delay:state=>{
			return state
		},  
	},
	mutations: { 
		setPublications(state, data){ 
			state.publications = data.publications;	 
		},  
		setDeleteStatus(state, data){ 
			state.delete.status = data.status;	 
			state.delete.id = data.id;	 
		},  
	},
	actions: { 
		async loadPublications({ commit }){ 			 
			await api.getPublication({ type:"all", status:"await" }).then((response)=>{
				if(response.result && response.data !== null) 
					commit('setPublications', response.data);	  
			})	 
		}, 
		async deletePublication({ state, commit, dispatch }){ 			 
			await api.deletePublication({ id:state.delete.id }).then((response)=>{
				if(response.result){ 
					dispatch('loadPublications');
					commit("setDeleteStatus", { status:false, id:null })
				}	  
			})	 
		}, 
		async changePublication({ state }, id){ 

			let formData = new FormData();  
			formData.append("action", "up");
			formData.append("id", id);
			formData.append("params", "status");
			formData.append("value", "edit");
			formData.append("request_status", false);
			formData.append("file", false);
			//{ action:"up", id, params:"status", value:"edit", request_status:false, file:false }
			let response = await api.upPublication(formData);
			return response.result
		},		 
	}
} 