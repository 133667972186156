import { createApp } from 'vue'  
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios' 
//import VuexOverlay from "vuex-overlay-tools" 
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'  
import '@/styles/styles.scss'
import ymapPlugin from 'vue-yandex-maps/dist/vue-yandex-maps.esm.js';

import VNum from "v3-num";
import TypeaheadInput from 'vue3-typeahead-input';
import 'vue3-typeahead-input/dist/style.css'; //Optional default CSS
import VueTheMask from 'vue-the-mask'
import money from 'v-money3'
import VueInputNumberPlugin from '@robin-rossow/vue-input-number'

import VueDraggableResizable from 'vue-draggable-resizable'

loadFonts()
 
const app = createApp(App)   
app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)
app.config.performance = true
app.config.devtools = true
//app.use(VuexOverlay) 
app.component('TypeaheadInput', TypeaheadInput)
app.component("vue-draggable-resizable", VueDraggableResizable)
app.use(vuetify)  
app.component('VueDatePicker', VueDatePicker)
app.config.isCustomElement = (tag) => tag.startsWith('y'); // <= This is doesn't work
app.use(ymapPlugin, {
    apiKey: '2628e0f5-1995-42bc-96a2-f2f944e6ec4a',
    lang: 'ru_RU',
    coordorder: 'latlong',
    enterprise: false,
    version: '2.1',
  });
app.use(VNum)  
app.use(money) 
app.use(VueInputNumberPlugin)
app.use(VueTheMask)    
app.mount('#app');